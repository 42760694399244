import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },

    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
        backgroundColor: 'red',
    },
}));

export default function CustomTableSortable(props) {
    const {
        headCells,
        rows,
        type,
        defaultOrderBy,
        width = '800px',
        rowClick = null,
        asc = 'asc',
        size = 'medium',
    } = props;

    const classes = useStyles();
    const [order, setOrder] = React.useState(asc);
    const [orderBy, setOrderBy] = React.useState(defaultOrderBy);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table style={{ minWidth: width }} aria-labelledby="tableTitle" aria-label="표" size={size}>
                        <CustomTableHead
                            headCells={headCells}
                            type={type}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                return rowClick ? (
                                    <TableRow key={'b' + index} onClick={() => rowClick(row.id)} hover>
                                        {headCells.map((head, ind) => {
                                            const column = head.id === 'no' ? index + 1 : row[head.id];
                                            return (
                                                <TableCell
                                                    key={index + 'k' + ind}
                                                    align={head.align || 'center'}
                                                    padding={head.disablePadding ? 'none' : 'normal'}
                                                >
                                                    {column}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ) : (
                                    <TableRow
                                        key={'b' + index}
                                        hover
                                        style={{
                                            backgroundColor: row['checked'] === '취소신청' ? '#FDEDEC' : null,
                                        }}
                                    >
                                        {headCells.map((head, ind) => {
                                            const column = head.id === 'no' ? index + 1 : row[head.id];
                                            return (
                                                <TableCell
                                                    key={index + 'k' + ind}
                                                    align={head.align || 'center'}
                                                    padding={head.disablePadding ? 'none' : 'normal'}
                                                >
                                                    {column}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function CustomTableHead(props) {
    const {
        classes,
        headCells,
        //type,
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow
                hover
                style={{
                    backgroundColor: '#fff5e9',
                }}
            >
                {headCells.map((headCell) => (
                    <TableCell
                        key={'h' + headCell.id}
                        align={headCell.align ?? 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? '내림차순' : '오름차순'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

CustomTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};
