import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getAfterSchools } from '../../redux/actions/adminActions';
// components
import AdminAddAfterSchool from './dialog/AdminAddAfterSchool';
import AdminEditAfterSchool from './dialog/AdminEditAfterSchool';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import { Box, Typography, Button, Divider } from '@material-ui/core';

import { DescriptionRounded, EditRounded, PersonAddRounded, VideoLibraryRounded } from '@material-ui/icons';
import AdminAfterSchoolVideos from './dialog/AdminAfterSchoolVideos';
import AdminAfterSchoolTeachingPlan from './dialog/AdminAfterSchoolTeachingPlan';
import CustomTableSortable from './table/CustomTableSortable';
import CenterLoading from '../../components/layout/CenterLoading';

const styles = (theme) => ({
    ...theme.userPage,
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    padding: {
        paddingRight: 3,
        paddingLeft: 3,
    },
});

const headCells = [
    { id: 'no', align: 'center', disablePadding: true, label: 'no.' }, //
    {
        id: 'title',
        align: 'center',
        disablePadding: true,
        label: '교실(class)',
    },
    { id: 'name', align: 'center', disablePadding: true, label: '교사명' },
    { id: 'phone', align: 'center', disablePadding: true, label: '휴대전화' },
    { id: 'startDay', align: 'center', disablePadding: true, label: '시작일' },
    { id: 'endDay', align: 'center', disablePadding: true, label: '종료일' },
    { id: 'type', align: 'center', disablePadding: true, label: '유형' },
    { id: 'createDay', align: 'center', disablePadding: true, label: '생성일' },
    { id: 'management', align: 'center', disablePadding: false, label: '상세' },
];

export const AdminAfterSchool = (props) => {
    const { adminLoading, afterSchools } = useSelector((state) => state.admin);
    const dispatch = useDispatch();

    const initial = {
        afterSchoolId: '',
        title: '',
        name: '',
        userId: '',
        userLevel: 9,
        phone: '',
        startDay: '',
        endDay: '',
        memo: '',
        type: '',
    };
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [vOpen, setVOpen] = useState(false);
    const [tOpen, setTOpen] = useState(false);
    const [info, setInfo] = useState(initial);
    const [rows, setRows] = useState([]);

    const handleEditOpen = (item) => {
        setEditOpen(true);
        setInfo(item);
    };

    useEffect(() => {
        dispatch(getAfterSchools());
    }, [dispatch]);

    useEffect(() => {
        if (afterSchools) {
            setRows(GetRows(afterSchools, handleEditOpen));
        }
    }, [afterSchools]);

    const handleSearch = () => {
        setOpen(true);
    };

    const handleVideoMgmtDialog = () => {
        setVOpen(true);
    };

    return (
        <Box p={1} m={1}>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: '100%', marginBottom: 5 }}
            >
                <Typography variant="h5">방과후(늘봄)교사 정보 관리(방세아)</Typography>
                <Box flexDirection="row" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<VideoLibraryRounded />}
                        onClick={handleVideoMgmtDialog}
                    >
                        영상관리
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        startIcon={<DescriptionRounded />}
                        onClick={() => setTOpen(true)}
                        style={{ marginLeft: '8px' }}
                    >
                        교안관리
                    </Button>
                    <Button
                        variant="contained"
                        color="inherit"
                        size="small"
                        startIcon={<PersonAddRounded />}
                        onClick={handleSearch}
                        style={{ marginLeft: '8px' }}
                    >
                        교사추가
                    </Button>
                </Box>
            </Box>
            <Divider light style={{ marginTop: 5, marginBottom: 5 }} />
            {adminLoading ? (
                <CenterLoading />
            ) : (
                <CustomTableSortable
                    {...{
                        headCells,
                        rows,
                        defaultOrderBy: 'createDay',
                        asc: 'desc',
                        // size: "small",
                    }}
                />
            )}

            {/* dialog */}
            <AdminAddAfterSchool {...{ open, setOpen }} />
            <AdminEditAfterSchool {...{ editOpen, setEditOpen, info, setInfo, initial }} />
            <AdminAfterSchoolVideos {...{ open: vOpen, setOpen: setVOpen }} />
            <AdminAfterSchoolTeachingPlan {...{ tOpen, setTOpen }} />
        </Box>
    );
};

function GetRows(rows, handleEditOpen) {
    if (rows.length === 0) {
        return [];
    }
    return rows.map((r) => ({
        ...r,
        createDay: dayjs(r.createdAt * 1000).format('YYYY-MM-DD'),
        management: (
            <Box
                key={r.id}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button size="small" color="primary">
                    {dayjs().isBefore(r.startDay) ? '대기' : dayjs().add(-1, 'day').isAfter(r.endDay) ? '만료' : ''}
                </Button>
                <Button
                    style={{ marginLeft: '10px' }}
                    variant="contained"
                    size="small"
                    onClick={() => handleEditOpen(r)}
                    startIcon={<EditRounded />}
                >
                    수정
                </Button>
            </Box>
        ),
    }));
}

export default withStyles(styles)(AdminAfterSchool);
