import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
// // Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getTeachingPlans } from '../../redux/actions/managerActions';
import { isLogged } from '../../redux/actions/userActions';
// components
import SmallImage1 from '../../images/main_small_5.png';
import SmallImage2 from '../../images/main_small_2.png';
import Tobeup from '../../images/tobe_uploaded.png';

import Mgo45ws from '../../images/mgo45ws.pdf';
import Mgo67ws from '../../images/mgo67ws.pdf';
import Pgo67ws from '../../images/pgo67ws.pdf';
import Pgo89ws from '../../images/pgo89ws.pdf';
import M31ws from '../../images/mgo_3_1_ws.pdf';
import M32ws from '../../images/mgo_3_2_ws.pdf';
import M33ws from '../../images/mgo_3_3_ws.pdf';
import M34ws from '../../images/mgo_3_4_ws.pdf';
import M41ws from '../../images/M41ws.pdf';
import M42ws from '../../images/M42ws.pdf';
import M43ws from '../../images/M43ws.pdf';
import M44ws from '../../images/M44ws.pdf';
import M51ws from '../../images/M51ws.pdf';
import M52ws from '../../images/M52ws.pdf';
import M53ws from '../../images/M53ws.pdf';
import M54ws from '../../images/M54ws.pdf';
import M61ws from '../../images/M61ws.pdf';
import M62ws from '../../images/M62ws.pdf';
import M63ws from '../../images/M63ws.pdf';
import M64ws from '../../images/M64ws.pdf';
import M71ws from '../../images/M71ws.pdf';
import M72ws from '../../images/M72ws.pdf';
import M73ws from '../../images/M73ws.pdf';
import M74ws from '../../images/M74ws.pdf';
import M81ws from '../../images/M81ws.pdf';
import M82ws from '../../images/M82ws.pdf';
import M83ws from '../../images/M83ws.pdf';
import M84ws from '../../images/M84ws.pdf';
import M91ws from '../../images/M91ws.pdf';
import M92ws from '../../images/M92ws.pdf';
import M93ws from '../../images/M93ws.pdf';
import M94ws from '../../images/M94ws.pdf';
import M101ws from '../../images/M101ws.pdf';
import M102ws from '../../images/M102ws.pdf';
import M103ws from '../../images/M103ws.pdf';
import M104ws from '../../images/M104ws.pdf';
import M111ws from '../../images/M111ws.pdf';
import M112ws from '../../images/M112ws.pdf';
import M113ws from '../../images/M113ws.pdf';
import M114ws from '../../images/M114ws.pdf';
import M121ws from '../../images/M121ws.pdf';
import M122ws from '../../images/M122ws.pdf';
import M123ws from '../../images/M123ws.pdf';
import M124ws from '../../images/M124ws.pdf';
//
import M11ws from '../../images/M11ws.pdf';
import M12ws from '../../images/M12ws.pdf';
import M13ws from '../../images/M13ws.pdf';
import M14ws from '../../images/M14ws.pdf';
import M21ws from '../../images/M21ws.pdf';
import M22ws from '../../images/M22ws.pdf';
import M23ws from '../../images/M23ws.pdf';
// import M24ws from "../../images/M24ws.pdf";
import M24ws from '../../images/M24ws.pdf';

import DummyDivider from '../layout/DummyDivider';
import VideoClip from '../home/VideoClip';

// MUI stuff
import { withStyles, useTheme } from '@material-ui/core/styles';

import {
    Grid,
    Box,
    Typography,
    Divider,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    useMediaQuery,
    Menu,
    MenuItem,
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import CenterLoading from '../layout/CenterLoading';
import {
    ArrowBackIosRounded,
    ArrowForwardIosRounded,
    DescriptionRounded,
    LibraryBooksRounded,
} from '@material-ui/icons';
// React-pdf
import { pdfjs, Document, Page } from 'react-pdf';
import { getAfterSchoolPlan, getAfterSchoolVideo } from '../../redux/actions/adminActions';
import { getPaidVideos } from '../../redux/actions/dataActions';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = (theme) => ({
    ...theme.userPage,
    back1: {
        backgroundColor: '#f9f9f9',
        height: 500,
        backgroundImage: `url(${SmallImage1})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '414px 400px',
        [theme.breakpoints.down('xs')]: {
            height: 400,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundSize: '414px 400px',
        },
    },
    back2: {
        backgroundColor: '#fff',
        height: 500,
        backgroundImage: `url(${SmallImage2})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '414px 400px',
        [theme.breakpoints.down('xs')]: {
            height: 400,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundSize: '414px 400px',
        },
    },
    back3: {
        backgroundColor: '#fff',
        height: 500,
        backgroundImage: `url(${SmallImage2})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '414px 400px',
    },
    tobeup: {
        width: '100%',
        backgroundImage: `url(${Tobeup})`,
        backgroundRepeat: 'no-repeat',
    },
});
const WhiteRadio = withStyles({
    root: { color: '#666' },
})(Radio);

export const VideoLessonForAfterSchool = (props) => {
    const { classes, value, openYN, history, isAfterSchoolOver, type } = props;
    const dispatch = useDispatch();
    const { videos, dataLoading } = useSelector((state) => state.data);
    const { teachingPlans } = useSelector((state) => state.manager);
    const { afterSchoolPlans, afterSchoolVideos } = useSelector((state) => state.admin);

    const productName = ['뮤직플레이 원데이클래스', '스마트피아노 원데이클래스', '뮤직플레이', '뮤직플레이 프리미엄'];
    const productGroupId = ['musicgogoOneDayClass', 'pianogogoOneDayClass', 'musicgogo', 'musicgogoPremium'];
    const categories = [
        ['4-5세', '6-7세'],
        ['6-7세', '8-9세'],
        ['플레이어', '러너'],
        ['플레이어', '러너'],
    ];

    // 워크시트는 보안상 수기 관리 (** FIXME:)
    const pdf_names = [
        'Mgo45ws',
        'Mgo67ws',
        'Pgo67ws',
        'Pgo89ws',
        'M31ws',
        'M32ws',
        'M33ws',
        'M34ws',
        'M41ws',
        'M42ws',
        'M43ws',
        'M44ws',
        'M51ws',
        'M52ws',
        'M53ws',
        'M54ws',
        'M61ws',
        'M62ws',
        'M63ws',
        'M64ws',
        'M71ws',
        'M72ws',
        'M73ws',
        'M74ws',
        'M81ws',
        'M82ws',
        'M83ws',
        'M84ws',
        'M91ws',
        'M92ws',
        'M93ws',
        'M94ws',
        'M101ws',
        'M102ws',
        'M103ws',
        'M104ws',
        'M111ws',
        'M112ws',
        'M113ws',
        'M114ws',
        'M121ws',
        'M122ws',
        'M123ws',
        'M124ws',
        'M11ws',
        'M12ws',
        'M13ws',
        'M14ws',
        'M21ws',
        'M22ws',
        'M23ws',
        'M24ws',
    ];
    const pdf_urls = [
        Mgo45ws,
        Mgo67ws,
        Pgo67ws,
        Pgo89ws,
        M31ws,
        M32ws,
        M33ws,
        M34ws,
        M41ws,
        M42ws,
        M43ws,
        M44ws,
        M51ws,
        M52ws,
        M53ws,
        M54ws,
        M61ws,
        M62ws,
        M63ws,
        M64ws,
        M71ws,
        M72ws,
        M73ws,
        M74ws,
        M81ws,
        M82ws,
        M83ws,
        M84ws,
        M91ws,
        M92ws,
        M93ws,
        M94ws,
        M101ws,
        M102ws,
        M103ws,
        M104ws,
        M111ws,
        M112ws,
        M113ws,
        M114ws,
        M121ws,
        M122ws,
        M123ws,
        M124ws,
        M11ws,
        M12ws,
        M13ws,
        M14ws,
        M21ws,
        M22ws,
        M23ws,
        M24ws,
    ];
    //

    const [category, setCategory] = useState(['4-5세', '6-7세', '플레이어', '플레이어']);
    // menu
    const [planMenu, setPlanMenu] = useState(null);
    const handleMenuOpen = (e) => {
        setPlanMenu(e.currentTarget);
    };
    const handleMenuClose = () => {
        setPlanMenu(null);
    };
    // workSheet
    const [wsMenu, setWsMenu] = useState(null);
    const handleWsOpen = (e) => {
        setWsMenu(e.currentTarget);
    };
    const handleWsClose = () => {
        setWsMenu(null);
    };

    const handleCategory = (e) => {
        let newCategory = [...category];
        newCategory[value] = e.target.value;
        setCategory(newCategory);
    };

    const typeId =
        type === '방과후' ? 'r53cCc6vvbhKaT97wZ5O' : type === '구독' ? 'dedalHbL0DPh2sNem1Z0' : 'r53cCc6vvbhKaT97wZ5O';

    useEffect(() => {
        dispatch(getPaidVideos());
        dispatch(getTeachingPlans());
        dispatch(getAfterSchoolVideo(typeId));
        dispatch(getAfterSchoolPlan(typeId));
    }, [dispatch, typeId]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const printPDF = (e, file) => {
        const res = dispatch(isLogged(history));
        if (res === 'logged') {
            e.preventDefault();
            window.open(file, 'PRINT', 'height=800,width=600');
        }
    };

    const teachingPlanInfos = teachingPlans
        .filter((t) => t.product === productName[value] && t.category === category[value])
        .filter(
            (p) =>
                dayjs(p.startDate).format('YYYYMMDD') <= dayjs().format('YYYYMMDD') &&
                dayjs(p.endDate).format('YYYYMMDD') >= dayjs().format('YYYYMMDD')
        );

    // const teachingPlanFile = pdfs[value][category[value]].plan;
    // const workSheetFile = pdfs[value][category[value]].ws;

    // view Pdf
    const [open2, setOpen2] = useState(false);
    const [viewFile, setViewFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }) => {
        //console.log("success");
        setNumPages(numPages);
    };

    const viewPdf = (file) => {
        const url_name = pdf_urls[pdf_names.indexOf(file)];
        setViewFile(url_name);
        //console.log(file, url_name);
        setOpen2(true);
    };

    // 비디오 노출 date check
    const today = dayjs().format('YYYYMMDD');

    const filteredVideo = videos
        .filter(
            (v) =>
                v.category === category[value] &&
                v.status === 'open' &&
                v.productGroupId === productGroupId[value] &&
                v.type === '유료'
        )
        .filter((v) => {
            if (v.productGroupId === 'musicgogo' || v.productGroupId === 'musicgogoPremium') {
                if (today >= dayjs(v.startDate).format('YYYYMMDD') && today <= dayjs(v.endDate).format('YYYYMMDD')) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        });
    console.log(value, filteredVideo);

    const descriptionMarkup = (
        <div>
            <DummyDivider bottom={30} />
            <Typography color="primary" variant="h5" gutterBottom>
                수업준비 사항
            </Typography>
            <Divider light />
            <DummyDivider top={30} />
            <Typography variant="body1" gutterBottom>
                수업받는 학생에 맞는 연령을 선택한 후 수업진도와 환경에 맞추어 영상을 재생해주세요. 영상 순서에 맞추어
                전체 영상을 재생하셔도 됩니다.
            </Typography>
            <Typography variant="body1" gutterBottom>
                우측 상단의 수업별/연령별 '교안'과 '워크시트'를 미리 살펴보시기를 바랍니다. 워크시트는 패키지에 포함되어
                배송됩니다.
            </Typography>
            <Typography variant="body1" gutterBottom>
                원활한 수업을 위해 다음과 같은 사전 준비를 해주시면 좋습니다.
            </Typography>
            <Typography variant="body1" gutterBottom>
                - 미리 교사들이 영상을 살펴보고, 어느 시점에 아이들을 케어해주는 것이 좋을지 미리 생각해보시기 바랍니다.
            </Typography>
            <Typography variant="body1" gutterBottom>
                - 영상 플레이 방법 및 문의사항이 있으시면 가맹/영업 담당자나, 뮤직플레이 담당자에게 연락주시거나, 카페를
                이용해주셔도 좋습니다.
            </Typography>
            <Typography variant="body1" gutterBottom>
                - 관련 교구 및 패키지 배송 관련하여, 미리 준비해주시면 더욱 좋습니다.
            </Typography>
            <DummyDivider bottom={30} />
        </div>
    );

    /* 구매자 없음 알림 */
    const noCustomerMarkup = (
        <Box m={fullScreen ? 1 : 3} p={fullScreen ? 1 : 10} className={classes.back3}>
            <Box align="center">
                {fullScreen ? <Box height={15} /> : null}
                <Typography variant={fullScreen ? 'h4' : 'h3'} color="primary" gutterBottom>
                    아직 수업 신청자가 없습니다.
                </Typography>
            </Box>
            <blockquote>
                <Typography variant={fullScreen ? 'subtitle2' : 'subtitle1'} color="textSecondary">
                    수업 신청자가 없을 시에는 영상이 공개되지 않습니다.
                </Typography>
                <Typography variant={fullScreen ? 'subtitle2' : 'subtitle1'} color="textSecondary">
                    자세한 수업 내역은 교안/워크시트를 살펴보시고, 연간교육계획안(뮤직플레이
                    {`>`}연간교육계획안 메뉴)에서 맛보기 영상 등을 확인하시기 바랍니다.
                </Typography>
            </blockquote>
        </Box>
    );
    const afterSchoolOverMarkup = (
        <Box m={fullScreen ? 1 : 3} p={fullScreen ? 1 : 10} className={classes.back3}>
            <Box align="center">
                {fullScreen ? <Box height={15} /> : null}
                <Typography variant={fullScreen ? 'h4' : 'h3'} color="primary" gutterBottom>
                    수업 진행 일자가 아닙니다.
                </Typography>
            </Box>
            <blockquote>
                <Typography variant={fullScreen ? 'subtitle2' : 'subtitle1'} color="textSecondary">
                    수업 시작일이 도래하지 않았거나, 종료일을 경과했을 시에는 영상이 공개되지 않습니다. 영업담당자에게
                    문의하시기 바랍니다.
                </Typography>
                <Typography variant={fullScreen ? 'subtitle2' : 'subtitle1'} color="textSecondary">
                    자세한 수업 내역은 교안/워크시트를 살펴보시고, 연간교육계획안(뮤직플레이
                    {`>`}연간교육계획안 메뉴)에서 맛보기 영상 등을 확인하시기 바랍니다.
                </Typography>
            </blockquote>
        </Box>
    );

    const realMarkup = (
        <Box m={1} p={1}>
            <FormControl component="fieldset">
                <RadioGroup aria-label="category" name="category" value={category[value]} onChange={handleCategory} row>
                    {categories[value].map((cate, ind) => (
                        <Box key={ind}>
                            <FormControlLabel
                                value={cate}
                                control={<WhiteRadio />}
                                //disabled={cate === '러너' && value===3}   /* // 프리미엄인 경우 */
                                label={<Typography>{cate} 수업</Typography>}
                            />
                            <Box height={20} />
                        </Box>
                    ))}
                </RadioGroup>
            </FormControl>
            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                    <Typography color="primary" variant="h5">
                        {productName[value]} ({category[value]}) 수업영상
                    </Typography>
                </Grid>
                {
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleMenuOpen}
                            startIcon={<DescriptionRounded />}
                        >
                            교안({category[value]})
                        </Button>
                        <Menu anchorEl={planMenu} keepMounted open={Boolean(planMenu)} onClose={handleMenuClose}>
                            {teachingPlanInfos
                                .filter((p) => p.type === '교안')
                                .sort((a, b) => a.order - b.order)
                                .map((v, i) => (
                                    <MenuItem key={i} onClick={(e) => printPDF(e, v.url)}>
                                        <Typography variant="subtitle2">{v.name}</Typography>
                                    </MenuItem>
                                ))}
                        </Menu>
                        <Button
                            style={{ marginLeft: 10 }}
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={handleWsOpen}
                            startIcon={<LibraryBooksRounded />}
                        >
                            워크시트 미리보기
                        </Button>
                        <Menu anchorEl={wsMenu} keepMounted open={Boolean(wsMenu)} onClose={handleWsClose}>
                            {teachingPlanInfos
                                .filter((p) => p.type === '워크시트')
                                .sort((a, b) => a.order - b.order)
                                .map((v, i) => (
                                    <MenuItem key={i} onClick={() => viewPdf(v.url)}>
                                        <Typography variant="subtitle2">{v.name}</Typography>
                                    </MenuItem>
                                ))}
                        </Menu>
                    </Grid>
                }
            </Grid>

            <Box height={20} />

            {openYN || value === 0 || value === 1 ? (
                <Grid container>
                    {dataLoading && <CenterLoading />}
                    {filteredVideo
                        .sort((a, b) => a.order - b.order)
                        .map((v, i) => (
                            <Grid item xs={12} sm={6} key={i}>
                                <VideoClip
                                    url={v.videoUrl}
                                    category={`[${v.videoName}]`}
                                    videoName={v.desc}
                                    runningTime={v.playtime}
                                />
                            </Grid>
                        ))}
                </Grid>
            ) : isAfterSchoolOver ? (
                afterSchoolOverMarkup
            ) : (
                noCustomerMarkup
            )}

            {descriptionMarkup}
            <Dialog open={open2} fullScreen={fullScreen}>
                <DialogContent>
                    <Box m={1} p={1}>
                        <Document
                            file={viewFile}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={<Typography>어린음악대.. 로딩중...</Typography>}
                            error={<Typography>지원하지 않는 브라우저입니다</Typography>}
                            noData={<Typography>준비중입니다..</Typography>}
                        >
                            <Page pageNumber={pageNumber} width={fullScreen ? 400 : 550} />
                        </Document>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Typography>
                        {pageNumber}/{numPages}
                    </Typography>
                    <Button
                        onClick={() => setPageNumber((prevPageNumber) => prevPageNumber - 1)}
                        color="secondary"
                        startIcon={<ArrowBackIosRounded />}
                        variant="contained"
                        disabled={pageNumber <= 1}
                    >
                        이전
                    </Button>
                    <Button
                        onClick={() => setPageNumber((prevPageNumber) => prevPageNumber + 1)}
                        color="secondary"
                        endIcon={<ArrowForwardIosRounded />}
                        variant="contained"
                        disabled={pageNumber >= numPages}
                    >
                        다음
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => setOpen2(false)}>
                        닫기
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );

    const forAfterSchool2Video = (
        <Box m={1} p={1}>
            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                    <Typography color="primary" variant="h5">
                        뮤직플레이 수업 영상
                    </Typography>
                </Grid>
                {/* 교안 */}
                <Grid item>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleMenuOpen}
                        startIcon={<DescriptionRounded />}
                    >
                        교안
                    </Button>
                    <Menu anchorEl={planMenu} keepMounted open={Boolean(planMenu)} onClose={handleMenuClose}>
                        {afterSchoolPlans
                            .filter((p) => p.type === '교안')
                            .sort((a, b) => a.order - b.order)
                            .map((v, i) => (
                                <MenuItem key={i} onClick={(e) => printPDF(e, v.url)}>
                                    <Typography variant="subtitle2">
                                        {v.name} ({v.category})
                                    </Typography>
                                </MenuItem>
                            ))}
                    </Menu>
                    <Button
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handleWsOpen}
                        startIcon={<LibraryBooksRounded />}
                    >
                        워크시트 미리보기
                    </Button>
                    <Menu anchorEl={wsMenu} keepMounted open={Boolean(wsMenu)} onClose={handleWsClose}>
                        {afterSchoolPlans
                            .filter((p) => p.type === '워크시트')
                            .sort((a, b) => a.order - b.order)
                            .map((v, i) => (
                                <MenuItem key={i} onClick={() => viewPdf(v.url)}>
                                    <Typography variant="subtitle2">
                                        {v.name} ({v.category})
                                    </Typography>
                                </MenuItem>
                            ))}
                    </Menu>
                </Grid>
            </Grid>

            <Box height={20} />
            {openYN ? (
                <Grid container>
                    {dataLoading && <CenterLoading />}
                    {afterSchoolVideos
                        .sort((a, b) => a.order - b.order)
                        .map((v, i) => (
                            <Grid item xs={12} sm={6} key={i}>
                                <VideoClip
                                    url={v.videoUrl}
                                    category={`[${v.videoName}]`}
                                    videoName={v.desc}
                                    //runningTime={v.playtime}
                                />
                            </Grid>
                        ))}
                </Grid>
            ) : isAfterSchoolOver ? (
                afterSchoolOverMarkup
            ) : (
                noCustomerMarkup
            )}

            <Dialog open={open2} fullScreen={fullScreen}>
                <DialogContent>
                    <Box m={1} p={1}>
                        <Document
                            file={viewFile}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={<Typography>어린음악대.. 로딩중...</Typography>}
                            error={<Typography>지원하지 않는 브라우저입니다</Typography>}
                            noData={<Typography>준비중입니다..</Typography>}
                        >
                            <Page pageNumber={pageNumber} width={fullScreen ? 400 : 550} />
                        </Document>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Typography>
                        {pageNumber}/{numPages}
                    </Typography>
                    <Button
                        onClick={() => setPageNumber((prevPageNumber) => prevPageNumber - 1)}
                        color="secondary"
                        startIcon={<ArrowBackIosRounded />}
                        variant="contained"
                        disabled={pageNumber <= 1}
                    >
                        이전
                    </Button>
                    <Button
                        onClick={() => setPageNumber((prevPageNumber) => prevPageNumber + 1)}
                        color="secondary"
                        endIcon={<ArrowForwardIosRounded />}
                        variant="contained"
                        disabled={pageNumber >= numPages}
                    >
                        다음
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => setOpen2(false)}>
                        닫기
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );

    if (value === 2) {
        return <Box>{forAfterSchool2Video}</Box>;
    } else {
        return <Box>{realMarkup}</Box>;
    }
};

export default withStyles(styles)(VideoLessonForAfterSchool);
