import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';
import { AddRounded, CancelRounded, RemoveRounded, SaveRounded, SearchRounded } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { useSelector, useDispatch } from 'react-redux';
import CenterLoading from '../../../components/layout/CenterLoading';
import { getFreeVideos, getPaidVideos } from '../../../redux/actions/dataActions';
import { getAfterSchoolVideo, updateAfterSchoolVideos } from '../../../redux/actions/adminActions';

export default function AdminAfterSchoolVideos({ open, setOpen }) {
    const { afterSchoolVideos } = useSelector((state) => state.admin);
    const { videos, freeVideos, dataLoading, dataErrors } = useSelector((state) => state.data);

    const dispatch = useDispatch();
    const [options, setOptions] = useState([]);
    const [myVideos, setMyVideos] = useState([{ videoId: '' }]);
    const [searchOpen, setSearchOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [typeOption, setTypeOption] = useState('방과후');
    const vId =
        typeOption === '방과후'
            ? 'r53cCc6vvbhKaT97wZ5O'
            : typeOption === '구독'
            ? 'dedalHbL0DPh2sNem1Z0'
            : 'r53cCc6vvbhKaT97wZ5O';
    useEffect(() => {
        setMyVideos([]);
        dispatch(getPaidVideos());
        dispatch(getFreeVideos());
        dispatch(getAfterSchoolVideo(vId));
    }, [dispatch, vId]);

    useEffect(() => {
        if (videos && freeVideos) {
            const newVs = [...videos.filter((v) => v.productGroupId !== 'musicgogoPremium'), ...freeVideos];
            setOptions(newVs);
        }
    }, [videos, freeVideos]);

    useEffect(() => {
        if (afterSchoolVideos) {
            setMyVideos(afterSchoolVideos);
        }
    }, [afterSchoolVideos]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSaveVideos = () => {
        const ids = myVideos.map((v) => v.videoId);
        if (ids.length === 0) {
            alert('선택된 영상이 없습니다.');
        } else if (ids.includes('')) {
            alert('빈 영상은 사용할 수 없습니다. 제거바랍니다.');
        } else {
            const conf = window.confirm(`${ids.length} 개의 영상이 선택되었습니다. 저장하시겠습니까?`);
            if (conf) {
                const args = {
                    id: vId, // 변경된 아이디
                    videos: ids,
                };
                dispatch(updateAfterSchoolVideos(args));
                setOpen(false);
            }
        }
    };
    const handleAdd = (index) => {
        setMyVideos((prev) => [...prev.slice(0, index + 1), { videoId: '' }, ...prev.slice(index + 1)]);
    };
    const handleDelete = (index) => {
        let newVideos = JSON.parse(JSON.stringify(myVideos));
        newVideos.splice(index, 1);
        setMyVideos(newVideos);
    };

    const handleSearch = (index) => {
        setSearchOpen(true);
        setSelectedIndex(index);
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <Box style={{ padding: 15 }}>
                <Typography variant="h4" gutterBottom>
                    제공 영상 관리
                </Typography>
                <Typography variant="caption">* 검색 아이콘을 눌러 영상을 검색하시기 바랍니다</Typography>
                <Typography variant="caption"> - 실시간 반영됩니다</Typography>
            </Box>
            <DialogContent style={{ minHeight: 400 }}>
                {dataLoading && <CenterLoading />}
                {dataErrors && <Typography>{dataErrors.errors}</Typography>}
                <Box style={{ marginBottom: 10 }}>
                    <Box display="flex" alignItems="center" flexDirection="row">
                        <FormLabel component="legend" style={{ marginRight: '20px' }}>
                            유형선택
                        </FormLabel>
                        <RadioGroup value={typeOption} onChange={(e) => setTypeOption(e.target.value)} row>
                            <FormControlLabel value="방과후" label="방과후" control={<Radio color="primary" />} />
                            <FormControlLabel value="구독" label="구독" control={<Radio color="primary" />} />
                        </RadioGroup>
                    </Box>
                    <Divider light />
                    <Box>
                        {myVideos.map((ai, index) => (
                            <Box
                                key={ai ? ai.videoId + '-' + index : 'no_' + index}
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <TextField
                                    value={ai.videoName}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    style={{ width: 250, backgroundColor: '#FAF3E7' }}
                                />
                                {/* search */}
                                <IconButton size="small" style={{ marginLeft: 15 }} onClick={() => handleSearch(index)}>
                                    <SearchRounded color="primary" />
                                </IconButton>
                                <IconButton size="small" style={{ marginLeft: 10 }} onClick={() => handleAdd(index)}>
                                    <AddRounded color="primary" />
                                </IconButton>
                                {index !== 0 && (
                                    <IconButton
                                        size="small"
                                        style={{ marginLeft: 10 }}
                                        onClick={() => handleDelete(index)}
                                    >
                                        <RemoveRounded color="primary" />
                                    </IconButton>
                                )}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" startIcon={<CancelRounded />} onClick={handleClose}>
                    닫기
                </Button>
                <Button variant="contained" color="primary" startIcon={<SaveRounded />} onClick={handleSaveVideos}>
                    저장
                </Button>
            </DialogActions>

            {/* search Dialog */}
            <SearchDialog
                {...{
                    searchOpen,
                    setSearchOpen,
                    selectedIndex,
                    options,
                    myVideos,
                    setMyVideos,
                }}
            />
        </Dialog>
    );
}

/* Search Dialog */

function SearchDialog({ searchOpen, setSearchOpen, selectedIndex, options, myVideos, setMyVideos }) {
    const [checked, setChecked] = useState(null);
    const handleClose = () => {
        setSearchOpen(false);
        setChecked(null);
    };
    const handleSave = () => {
        if (checked) {
            const isDup = myVideos.filter((v) => v.videoId === checked.videoId).length > 0;
            if (isDup) {
                alert('이미 선택된 영상입니다.');
            } else {
                setMyVideos(myVideos.map((v, i) => (i === selectedIndex ? checked : v)));
                setSearchOpen(false);
                setChecked(null);
            }
        } else {
            alert('항목을 선택해주세요.');
        }
    };
    return (
        <Dialog open={searchOpen} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogContent>
                <Typography gutterBottom variant="h5">
                    영상이름으로 검색
                </Typography>
                <Box style={{ width: 300 }}>
                    <Autocomplete
                        options={options.sort((a, b) => (a?.videoName > b?.videoName ? 1 : -1))}
                        getOptionLabel={(option) => option?.videoName}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" margin="dense" size="small" />
                        )}
                        renderOption={(option) => (
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>{option?.videoName}</Typography>
                                <Typography variant="caption">
                                    {option?.category} - {option?.productGroupId}
                                </Typography>
                            </Box>
                        )}
                        value={checked}
                        onChange={(e, newVal) => {
                            setChecked(newVal);
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={handleClose}>
                    닫기
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    적용
                </Button>
            </DialogActions>
        </Dialog>
    );
}
