import React from "react";
import {
  Box,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  InputLabel,
  Select,
  Chip,
  Button,
  Switch,
  DialogActions,
} from "@material-ui/core";
import { CloseRounded, DeleteRounded, SaveRounded } from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import CenterLoading from "../../../components/layout/CenterLoading";

function AdminHakwonInfoDialog(props) {
  const {
    //history,
    classes,
    open,
    setOpen, //
    editMode,
    hakwon,
    setHakwon,
    onChangeValue,
    //onChangeArea,
    onChangeAddress,
    onChangeContacts,
    onChangeSwitch,
    handleAddPhone,
    handleDeletePhone,
    handleDeleteImageData,
    handleAddPicture,
    handleImageChange,
    handleUpdateImageData,
    handleSave,
    proviceCode,
    addressCode,
    code,
    setCode, //
    area,
    setArea, //
    addPhone,
    setAddPhone,
    tempUrl,
    managerLoading,
    handleAddHakwon,
  } = props;
  const handleClose = () => {
    setOpen(false);
    setCode(0);
    setArea(0);
  };
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle style={{ backgroundColor: "#23a9f2" }}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseRounded />
        </IconButton>
        <Typography variant="h5">{hakwon.hName}</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {hakwon.hakwonId}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box p={2} m={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">구분</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={hakwon.partnerType || ""}
              onChange={(e) => {
                setHakwon((pre) => ({
                  ...pre,
                  partnerType: e.target.value,
                }));
              }}
              row
            >
              <FormControlLabel value="직영" control={<Radio />} label="직영" />
              <FormControlLabel value="가맹" control={<Radio />} label="가맹" />
            </RadioGroup>
          </FormControl>
          

          <Box mt={1}>
            <Typography gutterBottom>시/도 시군구 (고객 검색 지원용)</Typography>
            <FormControl variant="outlined" style={{ width: 250 }}>
              <InputLabel htmlFor="firstCode" color="secondary">
                시/도
              </InputLabel>
              <Select
                size="small"
                margin="dense"
                native
                value={code}
                onChange={(e) => {
                  setCode(Number(e.target.value));
                  setHakwon({
                    ...hakwon,
                    area: {
                      ...hakwon.area,
                      area1:
                        proviceCode.find(
                          (p) => p.code === Number(e.target.value)
                        ).province || "",
                    },
                  });
                }}
                color="secondary"
                label="시/도"
                inputProps={{
                  name: "code",
                  id: "firstCode",
                }}
              >
                <option aria-label="None" value="" />
                {proviceCode.map((pro) => (
                  <option key={pro.code} value={pro.code}>
                    {pro.province}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ width: 250 }}>
              <InputLabel htmlFor="secondCode" color="secondary">
                시/군/구
              </InputLabel>
              <Select
                size="small"
                margin="dense"
                native
                value={area}
                color="secondary"
                onChange={(e) => {
                  setArea(Number(e.target.value));
                  const adr = addressCode.find(
                    (a) => a.code === Number(e.target.value)
                  );
                  setHakwon({
                    ...hakwon,
                    area: {
                      ...hakwon.area,
                      area2: adr.area,
                      code: String(adr.code),
                    },
                  });
                }}
                label="시/군/구"
                inputProps={{
                  name: "area",
                  id: "secondCode",
                }}
              >
                <option aria-label="None" value="" />
                {addressCode.map(
                  (ad) =>
                    ad.provinceCode === Number(code) && (
                      <option key={ad.code} value={ad.code}>
                        {ad.area}
                      </option>
                    )
                )}
              </Select>
            </FormControl>
          </Box>

          <TextField
            label={`주소2 ${editMode ? "" : ": 시/도부터 도로명주소"}`}
            value={hakwon.address?.juso1}
            name="juso1"
            onChange={onChangeAddress}
            fullWidth
          />
          <TextField
            label={`주소2 ${editMode ? "" : ": ()에 들어가는 것. 생략가능"}`}
            value={hakwon.address?.juso2}
            name="juso2"
            onChange={onChangeAddress}
            fullWidth
          />
          <TextField
            label={`주소3 ${editMode ? "" : ": 실제 상세 주소"}`}
            value={hakwon.address?.juso3}
            name="juso3"
            onChange={onChangeAddress}
            fullWidth
          />
          <TextField
            label="핸드폰( - 포함)"
            value={hakwon.contacts?.mobile}
            name="mobile"
            onChange={onChangeContacts}
            fullWidth
          />
          <TextField
            label="전화번호 (- 포함)"
            value={hakwon.contacts?.tel}
            name="tel"
            onChange={onChangeContacts}
            fullWidth
          />
          <TextField
            label="원장이름"
            value={hakwon.ownerName}
            name="ownerName"
            onChange={onChangeValue}
            fullWidth
          />
          <Box height={10} />
          <Typography variant="subtitle2" gutterBottom>
            등급부여 전화번호
          </Typography>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            * 이 번호를 통해 학원장 레벨(level2) Batch작업을 수행합니다.
          </Typography>
          <br />
          <Typography variant="caption" color="textSecondary" gutterBottom>
            * 저장 버튼 누르기 전, 직접 여기에서 직접 추가/삭제 관리합니다.
          </Typography>
          <br />
          {hakwon.ownerPhone.map((pho) => (
            <Chip
              label={pho}
              variant="outlined"
              color="primary"
              deleteIcon={<DeleteRounded />}
              onDelete={() => handleDeletePhone(pho)}
            />
          ))}
          <TextField
            variant="outlined"
            size="small"
            label="번호 추가(-포함)"
            value={addPhone}
            onChange={(e) => setAddPhone(e.target.value)}
          />
          {editMode && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleAddPhone(addPhone)}
            >
              추가
            </Button>
          )}

          <TextField
            label="인스타"
            value={hakwon.instagram}
            name="instagram"
            onChange={onChangeValue}
            fullWidth
          />
          <TextField
            label="페이스북"
            value={hakwon.facebook}
            name="facebook"
            onChange={onChangeValue}
            fullWidth
          />
          <TextField
            label="블로그"
            value={hakwon.blog}
            name="blog"
            onChange={onChangeValue}
            fullWidth
          />

          <FormControlLabel
            control={
              <Switch
                checked={hakwon.isOpen}
                onChange={onChangeSwitch}
                name="isOpen"
              />
            }
            label={hakwon.isOpen ? "오픈" : "미 오픈"}
          />
          <br />
          <FormControlLabel
            control={
              <Switch
                checked={hakwon.inUse}
                onChange={onChangeSwitch}
                name="inUse"
              />
            }
            label={hakwon.inUse ? "서비스사용" : "미 사용"}
          />
          <br />
          <Box height={20} />
          <Typography variant="subtitle1">정산계좌</Typography>
          <TextField
            label="은행"
            value={hakwon.accountBank}
            name="accountBank"
            onChange={onChangeValue}
            fullWidth
          />
          <TextField
            label="계좌번호"
            value={hakwon.accountNumber}
            name="accountNumber"
            onChange={onChangeValue}
            fullWidth
          />
          <TextField
            label="예금주"
            value={hakwon.accountOwner}
            name="accountOwner"
            onChange={onChangeValue}
            fullWidth
          />
          <TextField
            label="주민번호"
            value={hakwon.accountNIN}
            name="accountNIN"
            onChange={onChangeValue}
            fullWidth
          />
          <Typography>이미지자료</Typography>
          {hakwon.accountImages && hakwon.accountImages.length > 0
            ? hakwon.accountImages.map((v, i) => (
                <Box key={i}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      window.open(v, "자료_" + Number(i + 1), "location=no")
                    }
                  >
                    {"자료_" + Number(i + 1)}
                  </Button>
                  <IconButton onClick={() => handleDeleteImageData(i)}>
                    <DeleteRounded />
                  </IconButton>
                </Box>
              ))
            : null}
          <Box>
            <Box>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleAddPicture}
              >
                +이미지
              </Button>
              <input
                type="file"
                id="imageInput"
                hidden="hidden"
                onChange={handleImageChange}
              />
            </Box>
            <Box>
              <Typography variant="caption" id="url">
                {tempUrl}
              </Typography>
              {managerLoading ? (
                <CenterLoading />
              ) : (
                tempUrl && <img src={tempUrl} alt="URL" />
              )}
              {tempUrl && (
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleUpdateImageData(tempUrl);
                    setOpen(false);
                  }}
                >
                  등록하고 닫기
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus={false}
          onClick={handleClose}
          color="primary"
          variant="outlined"
        >
          닫기
        </Button>
        {editMode ? (
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            startIcon={<SaveRounded />}
          >
            변경사항 저장
          </Button>
        ) : (
          <Button
            onClick={handleAddHakwon}
            color="secondary"
            variant="contained"
            startIcon={<SaveRounded />}
          >
            신규학원 생성/저장
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
const styles = (theme) => ({
  ...theme.userPage,
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  padding: {
    paddingRight: 3,
    paddingLeft: 3,
  },
});

export default withStyles(styles)(AdminHakwonInfoDialog);
