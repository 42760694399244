import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Typography,
    IconButton,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Divider,
} from '@material-ui/core';
import { CancelRounded, SaveRounded, SearchRounded, AddRounded, RemoveRounded } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { getTeachingPlans } from '../../../redux/actions/managerActions';
import { getAfterSchoolPlan, updateAfterSchoolPlans } from '../../../redux/actions/adminActions';

export default function AdminAfterSchoolTeachingPlan({ tOpen, setTOpen }) {
    const { teachingPlans } = useSelector((state) => state.manager);
    const { afterSchoolPlans } = useSelector((state) => state.admin);
    const dispatch = useDispatch();
    const [options, setOptions] = useState([]);
    const [myPlans, setMyPlans] = useState([{ teachingPlanId: '' }]);
    const [searchOpen, setSearchOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [typeOption, setTypeOption] = useState('방과후');
    const pId =
        typeOption === '방과후'
            ? 'r53cCc6vvbhKaT97wZ5O'
            : typeOption === '구독'
            ? 'dedalHbL0DPh2sNem1Z0'
            : 'r53cCc6vvbhKaT97wZ5O';

    useEffect(() => {
        setMyPlans([]);
        dispatch(getTeachingPlans());
        dispatch(getAfterSchoolPlan(pId));
    }, [dispatch, pId]);

    useEffect(() => {
        if (teachingPlans) {
            setOptions(
                teachingPlans.filter(
                    (p) => p.product !== '뮤직플레이 프리미엄' && p.yyyymm > dayjs().add(-1, 'year').format('YYYYMM')
                )
            );
        }
    }, [teachingPlans]);

    useEffect(() => {
        if (afterSchoolPlans) {
            setMyPlans(afterSchoolPlans);
        }
    }, [afterSchoolPlans]);

    const handleClose = () => {
        setTOpen(false);
    };
    const handleSave = () => {
        const ids = myPlans.map((v) => v.teachingPlanId);
        if (ids.length === 0) {
            alert('선택된 교안이 없습니다.');
        } else if (ids.includes('')) {
            alert('빈 교안은 사용할 수 없습니다. 제거바랍니다.');
        } else {
            const conf = window.confirm(`${ids.length} 개의 교안이 선택되었습니다. 저장하시겠습니까?`);
            if (conf) {
                const args = {
                    id: 'r53cCc6vvbhKaT97wZ5O',
                    plans: ids,
                };
                dispatch(updateAfterSchoolPlans(args));
                setTOpen(false);
            }
        }
    };

    const handleSearch = (index) => {
        setSearchOpen(true);
        setSelectedIndex(index);
    };
    const handleAdd = (index) => {
        setMyPlans((prev) => [...prev.slice(0, index + 1), { teachingPlanId: '' }, ...prev.slice(index + 1)]);
    };
    const handleDelete = (index) => {
        let newPlans = JSON.parse(JSON.stringify(myPlans));
        newPlans.splice(index, 1);
        setMyPlans(newPlans);
    };
    return (
        <Dialog open={tOpen} onClose={handleClose} fullWidth maxWidth="sm">
            <Box style={{ padding: 15 }}>
                <Typography variant="h4" gutterBottom>
                    교안 제공 관리
                </Typography>
                <Typography variant="caption">* 노출할 교안/워크시트 선택</Typography>
                <Typography variant="caption"> - * 순서는 관계없음</Typography>
            </Box>
            <DialogContent style={{ minHeight: 400 }}>
                <Box style={{ marginBottom: 10 }}>
                    <Box display="flex" alignItems="center" flexDirection="row">
                        <FormLabel component="legend" style={{ marginRight: '20px' }}>
                            유형선택
                        </FormLabel>
                        <RadioGroup row value={typeOption} onChange={(e) => setTypeOption(e.target.value)}>
                            <FormControlLabel value="방과후" label="방과후" control={<Radio />} />
                            <FormControlLabel value="구독" label="구독" control={<Radio />} />
                        </RadioGroup>
                    </Box>
                    <Divider light />
                </Box>
                {myPlans.map((p, index) => (
                    <Box
                        key={p ? p.teachingPlanId + '-' + index : 'no_' + index}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                    >
                        <TextField
                            value={p.type ? '[' + p?.type + ']' + p?.name + '-' + p?.product + '-' + p?.category : ''}
                            variant="outlined"
                            size="small"
                            margin="dense"
                            color="secondary"
                            style={{ width: 400, backgroundColor: '#effdd4' }}
                        />
                        {/* search */}
                        <IconButton size="small" style={{ marginLeft: 15 }} onClick={() => handleSearch(index)}>
                            <SearchRounded color="secondary" />
                        </IconButton>
                        <IconButton size="small" style={{ marginLeft: 10 }} onClick={() => handleAdd(index)}>
                            <AddRounded color="secondary" />
                        </IconButton>
                        {index !== 0 && (
                            <IconButton size="small" style={{ marginLeft: 10 }} onClick={() => handleDelete(index)}>
                                <RemoveRounded color="secondary" />
                            </IconButton>
                        )}
                    </Box>
                ))}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="outlined" onClick={handleClose} startIcon={<CancelRounded />}>
                    닫기
                </Button>
                <Button color="secondary" variant="contained" onClick={handleSave} startIcon={<SaveRounded />}>
                    저장
                </Button>
            </DialogActions>

            {/* search Dialog */}
            <SearchDialog
                {...{
                    searchOpen,
                    setSearchOpen,
                    selectedIndex,
                    options,
                    myPlans,
                    setMyPlans,
                }}
            />
        </Dialog>
    );
}

/* Search Dialog */

function SearchDialog({ searchOpen, setSearchOpen, selectedIndex, options, myPlans, setMyPlans }) {
    const [checked, setChecked] = useState(null);
    const handleClose = () => {
        setSearchOpen(false);
        setChecked(null);
    };
    const handleSave = () => {
        if (checked) {
            const isDup = myPlans.filter((v) => v.teachingPlanId === checked.teachingPlanId).length > 0;
            if (isDup) {
                alert('이미 선택된 항목입니다.');
            } else {
                setMyPlans(myPlans.map((v, i) => (i === selectedIndex ? checked : v)));
                setSearchOpen(false);
                setChecked(null);
            }
        } else {
            alert('항목을 선택해주세요.');
        }
    };
    return (
        <Dialog open={searchOpen} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogContent>
                <Typography gutterBottom variant="h5">
                    월, 주차 이름으로 검색
                </Typography>
                <Box style={{ width: 400 }}>
                    <Autocomplete
                        options={options.sort((a, b) => (a?.name > b?.name ? 1 : -1))}
                        getOptionLabel={(option) =>
                            '[' + option?.type + ']' + option?.name + '-' + option?.product + '-' + option?.category
                        }
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" margin="dense" size="small" color="secondary" />
                        )}
                        renderOption={(option) => (
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>{option?.name}</Typography>
                                <Typography variant="caption">
                                    {`[${option?.type}] ${option?.name} - ${option?.product} - ${option?.category} (${option?.yyyymm})`}
                                </Typography>
                            </Box>
                        )}
                        value={checked}
                        onChange={(e, newVal) => {
                            setChecked(newVal);
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={handleClose}>
                    닫기
                </Button>
                <Button variant="contained" color="secondary" onClick={handleSave}>
                    적용
                </Button>
            </DialogActions>
        </Dialog>
    );
}
