// User Reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const STOP_LOADING_USER = 'STOP_LOADING_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
export const SET_BADGE = 'SET_BADGE';

export const SET_KAKAO = 'SET_KAKAO';
export const SET_KAKAOINFO = 'SET_KAKAOINFO';

// UI Reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

// Data Reducer types
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCT = 'SET_PRODUCT';
export const LIKE_PRODUCT = 'LIKE_PRODUCT';
export const UNLIKE_PRODUCT = 'UNLIKE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const POST_PRODUCT = 'POST_PRODUCT';
export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_DATA = 'STOP_LOADING_DATA';
export const SET_DATA_ERRORS = 'SET_DATA_ERRORS';
export const CLEAR_DATA_ERRORS = 'CLEAR_DATA_ERRORS';

// enroll Reducer
export const SET_ERRORS_ENROLL = 'SET_ERRORS_ENROLL';
export const CLEAR_ERRORS_ENROLL = 'CLEAR_ERRORS_ENROLL';
export const LOADING_ENROLL = 'LOADING_ENROLL';
export const STOP_LOADING_ENROLL = 'STOP_LOADING_ENROLL';
export const SET_LESSONS = 'SET_LESSONS';
export const SET_LESSON = 'SET_LESSON';
export const SET_HAKWONS = 'SET_HAKWONS';

export const SET_MAINHAKWON = 'SET_MAINHAKWON';
export const SET_PRODUCTGROUP_ENROLL = 'SET_PRODUCTGROUP_ENROLL';
export const SET_PRODUCTGROUPALL_ENROLL = 'SET_PRODUCTGROUPALL_ENROLL';

export const SET_MOBILE_PAY_CHECK_RESULT = 'SET_MOBILE_PAY_CHECK_RESULT';

//export const SET_STUDENTS = 'SET_STUDENTS';
export const CHECK_STUDENTS = 'CHECK_STUDENTS';
export const INIT_STUDENTS = 'INIT_STUDENTS';
export const SET_STUDENT = 'SET_STUDENT';

// manager Reducer type
export const LOADING_MANAGER = 'LOADING_MANAGER';
export const STOP_LOADING_MANAGER = 'STOP_LOADING_MANAGER';
export const SET_MANAGER_HAKWON = 'SET_MANAGER_HAKWON';
export const SET_MANAGER_ERRORS = 'SET_MANAGER_ERRORS';
export const CLEAR_MANAGER_ERRORS = 'CLEAR_MANAGER_ERRORS';
export const SET_LESSONS_MANAGER = 'SET_LESSONS_MANAGER';
export const CLEAR_LESSONS_MANAGER = 'CLEAR_LESSONS_MANAGER';
export const SET_LESSON_MANAGER = 'SET_LESSON_MANAGER';
export const SET_PRODUCTS_MANAGER = 'SET_PRODUCTS_MANAGER';

export const SET_NEW_CATEGORY_LESSON = 'SET_NEW_CATEGORY_LESSON';
export const CLEAR_NEW_CATEGORY_LESSON = 'CLEAR_NEW_CATEGORY_LESSON';

// [DATA] curriculums
export const SET_CURRICULUM = 'SET_CURRICULUM';
export const SET_CURRICULUMS = 'SET_CURRICULUMS';
export const DELETE_CURRICULUM = 'DELETE_CURRICULUM';

// PRODUCT GROUP
export const SET_PRODUCTGROUP = 'SET_PRODUCTGROUP';
export const SET_PRODUCTGROUPALL = 'SET_PRODUCTGROUPALL';

// myclasses
export const SET_MYCLASSES = 'SET_MYCLASSES';
// teacher
export const SET_TEACHERS = 'SET_TEACHERS';
export const SET_TEACHER = 'SET_TEACHER';
export const SET_CHECKTEACHERS = 'SET_CHECKTEACHERS';
export const CLEAR_CHECKTEACHERS = 'CLEAR_CHECKTEACHERS';

// 학원 수강생들
export const SET_MEMBERS = 'SET_MEMBERS';
export const SET_PARENT = 'SET_PARENT';
export const CLEAR_PARENT = 'CLEAR_PARENT';

// 등록요청
export const SET_REQUESTS = 'SET_REQUESTS';

// 매출 sales
export const SET_SALES = 'SET_SALES';
export const CLEAR_SALES = 'CLEAR_SALES';
export const SET_SALE_DETAIL = 'SET_SALE_DETAIL';
export const CLEAR_SALE_DETAIL = 'CLEAR_SALE_DETAIL';
export const SET_SALES_SCHEDULE = 'SET_SALES_SCHEDULE';
export const SET_SALES_OTHERSCHEDULE = 'SET_SALES_OTHERSCHEDULE';
// 정산
export const SET_SETTLEMENTS = 'SET_SETTLEMENTS';
export const SET_SALES_SETTLEMENTS = 'SET_SALES_SETTLEMENTS';
export const CLEAR_SALES_SETTLEMENTS = 'CLEAR_SALES_SETTLEMENTS';
export const EDIT_SALES_SETTLEMENTS = 'EDIT_SALES_SETTLEMENTS';
export const UPDATE_SALES_SETTLEMENTS = 'UPDATE_SALES_SETTLEMENTS';
export const SET_SETTLEMENTS_IDS = 'SET_SETTLEMENTS_IDS';

// board
export const SET_BOARDS = 'SET_BOARDS';
export const CLEAR_BOARDS = 'CLEAR_BOARDS';
export const SET_URL = 'SET_URL';
export const SET_BOARD = 'SET_BOARD';
export const CLEAR_BOARD = 'CLEAR_BOARD';
export const SET_TEACHING_PLANS = 'SET_TEACHING_PLANS';

// video
export const SET_VIDEOS = 'SET_VIDEOS';
export const SET_FREE_VIDEOS = 'SET_FREE_VIDEOS';
export const CLEAR_VIDEOS = 'CLEAR_VIDEOS';

// admin
export const SET_ADMINS = 'SET_ADMINS';
export const SET_MANAGERS = 'SET_MANAGERS';
export const LOADING_ADMIN = 'LOADING_ADMIN';
export const STOP_LOADING_ADMIN = 'STOP_LOADING_ADMIN';
export const SET_ADMIN_ERRORS = 'SET_ADMIN_ERRORS';
export const CLEAR_ADMIN_ERRORS = 'CLEAR_ADMIN_ERRORS';
export const SET_ADMIN_LESSONS = 'SET_ADMIN_LESSONS';
export const SET_ADMIN_HAKWONS = 'SET_ADMIN_HAKWONS';
export const SET_ADMIN_KAKAOLINKS = 'SET_ADMIN_KAKAOLINKS';
export const SET_ADMIN_VISITLOG = 'SET_ADMIN_VISITLOG';
export const SET_ADMIN_HAKWON = 'SET_ADMIN_HAKWON';

// event
export const SET_EVENTS = 'SET_EVENTS';
// refund
export const SET_REFUNDS = 'SET_REFUNDS';
// afterschool
export const SET_SEARCH_USERS = 'SET_SEARCH_USERS';
export const SET_AFTERSCHOOLS = 'SET_AFTERSCHOOLS';
export const SET_AFTERSCHOOL_VIDEOS = 'SET_AFTERSCHOOL_VIDEOS';
export const SET_AFTERSCHOOL_PLANS = 'SET_AFTERSCHOOL_PLANS';

