import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { getHakwonInfo, getTeachersByHakwon, getSalesByHakwon } from '../redux/actions/managerActions';
import { getUserData } from '../redux/actions/userActions';

// components
import MainGridDark from '../components/layout/MainGridDark';
import Footer from '../components/layout/Footer';
import VideoLesson from '../components/manager/VideoLesson';
import LevelCheck from '../pages/manager/LevelCheck';
import NotAllowed from '../pages/manager/NotAllowed';

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import { Box, Toolbar, Tabs, Tab, Typography, Divider } from '@material-ui/core';
import NavBarDark from '../components/layout/NavBarDark';
import DummyDivider from '../components/layout/DummyDivider';
import VideoLessonForAfterSchool from '../components/manager/VideoLessonForAfterSchool';

const styles = (theme) => ({
    ...theme.userPage,
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25%',
    },
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
});
const productTabs = [
    { id: 1, label: '뮤직플레이 원데이클래스' },
    { id: 2, label: '스마트피아노 원데이클래스' },
    { id: 3, label: '뮤직플레이' },
    { id: 4, label: '뮤직플레이 프리미엄' },
];
// dayjs에 타임존 플러그인 추가
dayjs.extend(utc);
dayjs.extend(timezone);

export const Video = (props) => {
    const { classes, history } = props;
    const [value, setValue] = useState(2);
    const { credentials, rnr } = useSelector((state) => state.user);
    const { hakwon, sales } = useSelector((state) => state.manager);
    const [allowed, setAllowed] = useState(false); // 권한이 있는지 확인
    const dispatch = useDispatch();

    if (rnr.level === 8) {
        return <ForAfterSchool {...{ value, setValue, credentials, dispatch, classes, history, rnr }} />;
    } else {
        return (
            <ForMemberVideo
                {...{
                    value,
                    setValue,
                    hakwon,
                    sales,
                    allowed,
                    setAllowed,
                    dispatch,
                    classes,
                    history,
                    credentials,
                    rnr,
                }}
            />
        );
    }
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    component="div"
                    style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        maginLeft: 0,
                        marginRight: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 80, // 60
        height: 40,
        backgroundColor: '#181b2c',
        fontWeight: theme.typography.fontWeightRegular,
        margin: 0,
        '&:hover': {
            color: theme.palette.secondary.main,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: '#181b2c',
        },
        '&:focus': {
            color: theme.palette.secondary.main,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

/* 늘봄교사용 페이지 */
function ForAfterSchool({ value, setValue, credentials, dispatch, classes, history, rnr }) {
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const now = dayjs().tz('Asia/Seoul'); // 현재 시간 (KST 기준)
    const startDate = dayjs(rnr?.afterSchool?.startDay).tz('Asia/Seoul'); // 시작일 (KST 기준)
    const endDate = dayjs(rnr?.afterSchool?.endDay).add(1, 'day').tz('Asia/Seoul');
    let openYN = false;
    if (now.isAfter(startDate) && now.isBefore(endDate)) {
        openYN = true;
    }
    return (
        <>
            <NavBarDark menu={4} />
            <div className="container">
                <MainGridDark>
                    <Toolbar style={{ marginRight: 0, marginLeft: 0 }}>
                        <Tabs
                            orientation="horizontal"
                            variant="scrollable"
                            scrollButtons="auto"
                            value={value}
                            onChange={handleChange}
                            aria-label="tabs"
                            className={classes.tabs}
                            indicatorColor="secondary"
                            //fullWidth
                        >
                            {/* 0~2가지 3개 탭만 제공 */}
                            {productTabs.slice(0, 3).map((t, index) => (
                                <StyledTab key={index} label={t.label} {...a11yProps(index)} />
                            ))}
                        </Tabs>
                    </Toolbar>

                    {productTabs.slice(0, 3).map((t, i) => (
                        <TabPanel value={value} index={i} key={i}>
                            <VideoLessonForAfterSchool
                                value={value}
                                openYN={openYN}
                                history={history}
                                isAfterSchoolOver
                                type={rnr?.afterSchool?.type}
                            />
                        </TabPanel>
                    ))}
                    {/* end of tabs */}
                    <Box m={1} p={1}>
                        <Typography color="primary" variant="h5" gutterBottom>
                            영상 수업 정보
                        </Typography>
                        <Divider light />
                        <ul>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {`클래스 : ${rnr?.afterSchool?.title || '뮤직플레이'}`}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom>
                                    {`담당선생님 : ${rnr?.afterSchool?.name}(${rnr?.afterSchool?.phone})`}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom style={{ fontWeight: 500 }}>
                                    {`시작일 : ${rnr?.afterSchool?.startDay} 00:00`}
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" gutterBottom style={{ fontWeight: 500 }}>
                                    {`종료일 : ${rnr?.afterSchool?.endDay} 23:59`}
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="body1" gutterBottom style={{ marginLeft: 20 }}>
                            관련 문의는 영업담당자에게 연락주시기 바랍니다.
                        </Typography>

                        <DummyDivider bottom={10} />
                    </Box>

                    <Footer />
                </MainGridDark>
            </div>
        </>
    );
}

function ForMemberVideo({
    value,
    setValue,
    hakwon,
    sales,
    allowed,
    setAllowed,
    dispatch,
    classes,
    history,
    credentials,
    rnr,
}) {
    if (!localStorage.getItem('hId')) {
        localStorage.setItem('hId', credentials.myHakwon?.hakwonId);
    }
    const hId = credentials ? credentials.myHakwon?.hakwonId : localStorage.getItem('hId');

    // hakwon 세팅
    useEffect(() => {
        dispatch(getHakwonInfo());
        dispatch(getUserData());
    }, [dispatch]);

    // 교사정보불러오기
    useEffect(() => {
        dispatch(getTeachersByHakwon(hId));
        dispatch(getSalesByHakwon(hId, { from: 1577836800, to: 4102444800 }));
    }, [dispatch, hId]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /* 구매 이력 오픈여부 결정 */
    const today = dayjs().format('YYYYMMDD');
    const filteredSales = sales.filter((sa) => {
        //'paid' , 'regular' //FIXME: 오픈 여부 결정
        if (sa.status === 'paid' && (sa.lessonType === 'regular' || sa.lessonType === 'premium')) {
            const sdt = dayjs(sa.lessonDate * 1000).format('YYYYMMDD');
            const span = sa.totalRound ? sa.totalRound : sa.price === 45000 ? 3 : 1;

            const edt = dayjs(sa.lessonDate * 1000)
                .add(span, 'month')
                .format('YYYYMMDD');
            // 첫수업시작일이 15일이후까지는 허용(10일에서 변경)
            const changed_sdt =
                dayjs(sdt).get('date') > 15
                    ? dayjs(sdt).add(-2, 'day').format('YYYYMMDD')
                    : dayjs(sdt)
                          .set('date', 25)
                          .set('month', dayjs(sdt).get('month') - 1)
                          .format('YYYYMMDD');
            const changed_edt =
                dayjs(edt).get('date') > 15
                    ? dayjs(edt)
                          .set('date', 2)
                          .set('month', dayjs(edt).get('month') + 1)
                          .format('YYYYMMDD')
                    : dayjs(edt).add(2, 'day');

            //console.log(sdt, edt, today, changed_sdt, changed_edt, today >= changed_sdt && today <= changed_edt);

            if (today >= changed_sdt && today <= changed_edt) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });
    const openYN = filteredSales.length > 0 || rnr.level < 2 || rnr.level === 8; //관리자에게는 다보임
    return (
        <>
            <NavBarDark menu={4} />
            <div className="container">
                <MainGridDark>
                    <LevelCheck
                        checkingTeacherLevel={3}
                        setAllowed={setAllowed}
                        allowed={allowed}
                        hakwon={hakwon}
                        rnr={rnr}
                        credentials={credentials}
                    />

                    {<NotAllowed allowed={allowed} rnr={rnr} />}
                    {allowed && (
                        <Toolbar style={{ marginRight: 0, marginLeft: 0 }}>
                            <Tabs
                                orientation="horizontal"
                                variant="scrollable"
                                scrollButtons="auto"
                                value={value}
                                onChange={handleChange}
                                aria-label="tabs"
                                className={classes.tabs}
                                indicatorColor="secondary"
                                //fullWidth
                            >
                                {productTabs.map((t, index) => (
                                    <StyledTab key={index} label={t.label} {...a11yProps(index)} />
                                ))}
                            </Tabs>
                        </Toolbar>
                    )}
                    {allowed &&
                        productTabs.map((t, i) => (
                            <TabPanel value={value} index={i} key={i}>
                                <VideoLesson value={value} openYN={openYN} history={history} />
                            </TabPanel>
                        ))}
                    {/* end of tabs */}

                    <Footer />
                </MainGridDark>
            </div>
        </>
    );
}

export default withStyles(styles)(Video);
